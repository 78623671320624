import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteJSON, fetchJSON, postJSON, putJSON } from 'api/fetch';

import { PAYROLL_RUN_TYPES } from 'features/payroll/PayrollRunView/constants';

export const fetchInitialData = createAsyncThunk(
  'payroll/fetchInitialData',
  (payrollRunId, { rejectWithValue }) =>
    fetchJSON(`/payroll/runs/${payrollRunId}.json`).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const distributeTips = payload => {
  const callDistributeTips =
    payload.input_hours === 'import' || !!payload.payroll_run_id;

  if (callDistributeTips) {
    return postJSON('/tip_pooling/for_payroll', payload);
  }

  return Promise.resolve();
};

export const autoUpdatePayrollRun = createAsyncThunk(
  'payroll/autoUpdatePayrollRun',
  ({ payrollRunId }, { getState, rejectWithValue }) =>
    distributeTips({ payroll_run_id: payrollRunId }, getState)
      .then(() =>
        fetchJSON(`/payroll/runs/${payrollRunId}/auto_update`).catch(err =>
          err.response.json().then(body => rejectWithValue(body))
        )
      )
      .catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const createPayrollRun = createAsyncThunk(
  'payroll/createPayrollRun',
  ({ payload }, { getState, rejectWithValue }) =>
    distributeTips({ input_hours: 'import' }, getState)
      .then(() =>
        postJSON(`/payroll/runs`, payload).catch(err =>
          err.response.json().then(body => rejectWithValue(body))
        )
      )
      .catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const getLatePaydayForPayrollRun = createAsyncThunk(
  'payroll/getLatePaydayForPayrollRun',
  (payload, { rejectWithValue }) =>
    fetchJSON(`/payroll/runs/get_late_payday`).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const checkRunSubmissionDeadline = createAsyncThunk(
  'payroll/checkRunSubmissionDeadline',
  ({ payrollRunId }, { rejectWithValue }) =>
    fetchJSON(`/payroll/runs/${payrollRunId}/check_submission_deadline`).catch(
      err => err.response.json().then(body => rejectWithValue(body))
    )
);

export const createOffCyclePayrollRun = createAsyncThunk(
  'payroll/createOffCyclePayrollRun',
  ({ payload }, { getState, rejectWithValue }) =>
    distributeTips(payload, getState)
      .then(() =>
        postJSON('/payroll/off_cycle', payload).catch(err =>
          err.response.json().then(body => rejectWithValue(body))
        )
      )
      .catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const updateOffCyclePayrollRun = createAsyncThunk(
  'payroll/updateOffCyclePayrollRun',
  ({ payrollRunId, payload }, { rejectWithValue }) =>
    putJSON(`/payroll/off_cycle/${payrollRunId}.json`, payload).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const fetchOffCycleSetupFormInitialData = createAsyncThunk(
  'payroll/fetchOffCycleSetupFormInitialData',
  payrollRunId => {
    const url = payrollRunId
      ? `/payroll/off_cycle/${payrollRunId}/edit.json`
      : '/payroll/off_cycle.json';
    return fetchJSON(url);
  }
);

// Multi-wage update earning
export const updateEarning = createAsyncThunk(
  'payroll/updateEarning',
  ({ earningId, value }, { rejectWithValue }) => {
    value = value === '' ? 0 : value;
    return putJSON(`/payroll/earnings/${earningId}.json`, { value }).catch(
      err => err.response.json().then(body => rejectWithValue(body))
    );
  }
);

// Multi-wage update payment method and skip
export const updatePaymentMethod = createAsyncThunk(
  'payroll/updatePaymentMethod',
  ({ paymentId, paymentType, value }, { rejectWithValue }) => {
    const paymentEntity =
      paymentType === 'contractor_payments' ? 'contractors' : 'items';
    const url = `/payroll/payments/${paymentEntity}/${paymentId}/update_payment_method.json`;
    return putJSON(url, { value }).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    );
  }
);

// Multi-wage update bonus/other earnings
export const saveOtherEarnings = createAsyncThunk(
  'payroll/saveOtherEarnings',
  ({ paymentId, paymentType, params }, { rejectWithValue }) => {
    const paymentEntity =
      paymentType === 'contractor_payments' ? 'contractors' : 'items';
    const url = `/payroll/payments/${paymentEntity}/${paymentId}/update_other_earnings.json`;
    return putJSON(url, params).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    );
  }
);

export const savePaystubNotes = createAsyncThunk(
  'payroll/savePaystubNotes',
  ({ paymentId, paymentType, params }, { rejectWithValue }) => {
    const paymentEntity =
      paymentType === 'contractor_payments' ? 'contractors' : 'items';
    const url = `/payroll/payments/${paymentEntity}/${paymentId}/update_paystub_notes.json`;
    return putJSON(url, params).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    );
  }
);

export const saveViewSettings = createAsyncThunk(
  'payroll/saveViewSettings',
  ({ area, settings }, { rejectWithValue }) => {
    const url = `/payroll/view_settings/upsert.json`;
    return putJSON(url, { area, settings }).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    );
  }
);

export const previewPayroll = createAsyncThunk(
  'payroll/previewPayroll',
  (payrollRunId, { rejectWithValue }) =>
    postJSON(
      `/payroll/run_workflows/${payrollRunId}/preview_payroll.json`
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const getFinalCosts = createAsyncThunk(
  'payroll/getFinalCosts',
  (payrollRunId, { rejectWithValue }) =>
    fetchJSON(`/payroll/run_workflows/${payrollRunId}/final_costs.json`).catch(
      err => err.response.json().then(body => rejectWithValue(body))
    )
);

export const setInProgress = createAsyncThunk(
  'payroll/setInProgress',
  payload => {
    const { payrollRunId, ...params } = payload;

    if (!!payrollRunId) {
      return postJSON(
        `/payroll/run_workflows/${payrollRunId}/set_in_progress`,
        params
      );
    }
  }
);

export const approvePayroll = createAsyncThunk(
  'payroll/approvePayroll',
  (
    {
      payrollRunId,
      missingStateTaxAgreementCheckboxValue,
      nsfPreventionAgreementCheckboxValue,
    },
    { rejectWithValue }
  ) =>
    postJSON(`/payroll/run_workflows/${payrollRunId}/approve_payroll.json`, {
      missing_state_tax_agreement_checked:
        missingStateTaxAgreementCheckboxValue,
      nsf_prevention_agreement_checked: nsfPreventionAgreementCheckboxValue,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const reopenPayroll = createAsyncThunk(
  'payroll/reopenPayroll',
  (payrollRunId, { rejectWithValue }) =>
    postJSON(
      `/payroll/run_workflows/${payrollRunId}/reopen_payroll.json`
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const deletePayroll = createAsyncThunk(
  'payroll/deletePayroll',
  payrollRunId => deleteJSON(`/payroll/run_workflows/${payrollRunId}.json`)
);

export const fetchTimecardsForRole = createAsyncThunk(
  'payroll/fetchTimecardsForRole',
  (payload, { rejectWithValue }) => {
    const { payrollRunId, ...params } = payload;
    let url = `/payroll/run_workflows/${payrollRunId}/get_timecards.json?item_id=${params.item_id}`;

    if (params.role_id) {
      url += `&role_id=${params.role_id}`;
    }

    url += `&payment_type=${params.payment_type}`;

    return fetchJSON(url).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    );
  }
);

const initOffCycle = () => ({
  setupForm: {
    calendarPayrollPeriods: [],
    nextPayrollDates: [],
    employeesForOptions: [],
    holidays: [],
    values: null,
    companyStartDate: null,
  },
  isFetching: false,
});

const initEarning = () => ({
  isFetching: false,
});

const payrollRunSlice = createSlice({
  name: 'payrollRun',

  initialState: {
    openPayrollRunId: null,
    columns: [],
    roles: [],
    timecardModalColumns: [],
    timecards: {},
    payPeriodStartDate: null,
    payPeriodEndDate: null,
    payPeriod: '',
    inputHours: null,
    runType: null,
    directDepositDisabled: false,
    payrollPayments: [],
    totals: null,
    autoUpdatePayments: false,
    netPayPayments: [],
    netPayTotals: null,
    netPayColumns: [],
    isFetching: false,
    errors: [],
    errorType: null,
    activeStep: 1,
    headerPayday: null,
    headerSubmissionDeadline: null,
    actualSubmissionDeadlineCountdown: null,
    isPastActualSubmissionDeadline: false,
    submitView: {
      payments: null,
      hasManualPayments: false,
      nonSufficientFunds: true,
      reviewPayday: null,
      isContractorsOnlyCompany: false,
      showAutoPayrollPromo: false,
    },
    offCycle: initOffCycle(),
    earning: initEarning(),
    showCoachmarks: false,
    showTipsCoachmarks: false,
    showMissingStateTaxAgreement: {
      disclaimer: false,
      checkbox: false,
      modal_message: false,
    },
    showCharityNpoEarnings: false,
    canShowManualHoursModal: false,
    showReligiousNpoEarnings: false,
    missingStateTaxAgreementDeadline: '',
    locations: [],
    viewSettings: {
      name_display: 'preferred',
      sort_by: 'last_name',
      earnings_filter: '',
      location_filter: [],
      column_visibility: {},
    },
    showColumnSettings: false,
    showFilters: false,
  },

  reducers: {
    setOpenPayrollRunId: (state, action) => {
      state.openPayrollRunId = action.payload;
    },

    updateClientValue: (state, action) => {
      state.payrollPayments[action.payload.rowIndex][action.payload.field] =
        action.payload.value;
    },

    updateActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },

    saveSetupFormValues: (state, action) => {
      state.offCycle.setupForm.values = action.payload;
    },

    resetTimecards: state => {
      state.timecards = [];
    },

    teardownStandard: state => ({
      ...state,
      payrollPayments: [],
      activeStep: 1,
    }),

    teardownOffCycle: state => ({
      ...state,
      offCycle: initOffCycle(),
      payrollPayments: [],
      activeStep: 1,
    }),

    resetShowCoachmarks: state => {
      state.showCoachmarks = false;
    },

    setCanShowManualHoursModal: (state, action) => {
      state.canShowManualHoursModal = action.payload;
    },

    setAutoUpdatePayments: (state, action) => {
      state.autoUpdatePayments = action.payload;
    },

    updateUserPaymentMethod: (state, action) => {
      const payrollPayments = state.payrollPayments;
      payrollPayments.forEach(payment => {
        if (payment.user.id === action.payload.userId) {
          payment.payment_method = action.payload.paymentMethod;
        }
      });
      state.payrollPayments = payrollPayments;
    },

    setViewSettings: (state, action) => {
      state.viewSettings = { ...state.viewSettings, ...action.payload };
    },

    toggleColumnSettings: state => {
      state.showFilters = false;
      state.showColumnSettings = !state.showColumnSettings;
    },

    toggleFilters: state => {
      state.showColumnSettings = false;
      state.showFilters = !state.showFilters;
    },

    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchInitialData.fulfilled, (state, action) => {
      state.isFetching = false;
      state.columns = action.payload.columns;
      state.roles = action.payload.roles;
      state.timecardModalColumns = action.payload.timecard_modal_columns;
      state.payPeriodStartDate = action.payload.pay_period_start;
      state.payPeriodEndDate = action.payload.pay_period_end;
      state.payPeriod = action.payload.pay_period;
      state.netPayColumns = action.payload.net_pay_columns;
      state.headerPayday = action.payload.header_payday;
      state.headerSubmissionDeadline =
        action.payload.header_submission_deadline;
      state.actualSubmissionDeadlineCountdown =
        action.payload.actual_submission_deadline_countdown;
      state.runType = PAYROLL_RUN_TYPES[action.payload.run_type];
      state.directDepositDisabled = action.payload.direct_deposit_disabled;
      state.inputHours = action.payload.input_hours;
      state.submitView.reviewPayday = action.payload.review_payday;
      state.payrollPayments = action.payload.payments;
      state.totals = action.payload.totals;
      state.showCoachmarks = action.payload.show_coachmarks;
      state.showTipsCoachmarks = action.payload.show_tips_coachmarks;
      state.showMissingStateTaxAgreement =
        action.payload.show_missing_state_tax_agreement;
      state.missingStateTaxAgreementDeadline =
        action.payload.missing_state_tax_agreement_deadline;
      state.showCharityNpoEarnings = action.payload.show_charity_npo_earnings;
      state.showReligiousNpoEarnings =
        action.payload.show_religious_npo_earnings;
      state.submitPayrollBlocked = action.payload.submit_payroll_blocked;
      state.canShowManualHoursModal =
        action.payload.can_show_manual_hours_modal;
      state.locations = action.payload.locations;
      state.viewSettings = {
        ...state.viewSettings,
        ...action.payload.view_settings,
      };
    });

    builder.addCase(fetchInitialData.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(fetchInitialData.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(createPayrollRun.pending, state => {
      state.isFetching = true;
      state.errors = [];
    });

    builder.addCase(createPayrollRun.rejected, (state, action) => {
      state.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(createPayrollRun.fulfilled, state => {
      state.isFetching = false;
      state.runType = PAYROLL_RUN_TYPES.standard;
      state.errors = [];
    });

    builder.addCase(autoUpdatePayrollRun.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(autoUpdatePayrollRun.rejected, (state, action) => {
      state.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(autoUpdatePayrollRun.fulfilled, state => {
      state.isFetching = false;
    });

    builder.addCase(checkRunSubmissionDeadline.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(checkRunSubmissionDeadline.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isPastActualSubmissionDeadline =
        action.payload.is_past_actual_submission_deadline;
    });

    builder.addCase(createOffCyclePayrollRun.pending, state => {
      state.offCycle.isFetching = true;
      state.errors = [];
    });

    builder.addCase(createOffCyclePayrollRun.rejected, (state, action) => {
      state.offCycle.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(createOffCyclePayrollRun.fulfilled, state => {
      state.offCycle.isFetching = false;
      state.runType = PAYROLL_RUN_TYPES.off_cycle;
      state.errors = [];
    });

    builder.addCase(updateOffCyclePayrollRun.pending, state => {
      state.isFetching = true;
      state.errors = [];
    });

    builder.addCase(updateOffCyclePayrollRun.rejected, (state, action) => {
      state.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(updateOffCyclePayrollRun.fulfilled, state => {
      state.isFetching = false;
      state.runType = PAYROLL_RUN_TYPES.off_cycle;
      state.errors = [];
    });

    builder.addCase(fetchOffCycleSetupFormInitialData.pending, state => {
      state.offCycle.isFetching = true;
    });

    builder.addCase(
      fetchOffCycleSetupFormInitialData.fulfilled,
      (state, action) => {
        state.offCycle.setupForm.calendarPayrollPeriods =
          action.payload.calendarPayrollPeriods;
        state.offCycle.setupForm.holidays = action.payload.holidays;
        state.offCycle.setupForm.companyStartDate =
          action.payload.companyStartDate;
        state.offCycle.setupForm.employeesForOptions =
          action.payload.employeesForOptions;
        state.offCycle.setupForm.nextPayrollDates =
          action.payload.nextPayrollDates;
        state.offCycle.setupForm.holdays = action.payload.holidays;

        if (action.payload.setupValues) {
          state.offCycle.setupForm.values = action.payload.setupValues;
        }
        state.offCycle.isFetching = false;
      }
    );

    builder.addCase(saveOtherEarnings.fulfilled, (state, action) => {
      const {
        id: paymentId,
        payment_type: paymentType,
        updated_earnings: updatedEarnings,
        reimbursement,
        reimbursement_amount: reimbursementAmount,
        gross,
        totals,
      } = action.payload;

      state.headerSubmissionDeadline =
        action.payload.header_submission_deadline;
      state.actualSubmissionDeadlineCountdown =
        action.payload.actual_submission_deadline_countdown;

      state.isUpdating = false;
      state.errors = [];

      // Find the payment that was updated
      const payrollPaymentIndex = state.payrollPayments.findIndex(
        payrollPayment =>
          payrollPayment.id === paymentId &&
          payrollPayment.payment_type === paymentType
      );
      if (payrollPaymentIndex === -1) {
        return;
      }

      const payrollPayment = state.payrollPayments[payrollPaymentIndex];
      payrollPayment.gross.current = gross;
      payrollPayment.reimbursement = reimbursement;
      payrollPayment.reimbursement_amount = reimbursementAmount;
      updatedEarnings.forEach(
        earning => (payrollPayment.singular_earnings[earning.column] = earning)
      );

      state.payrollPayments[payrollPaymentIndex] = payrollPayment;
      state.totals.current = totals;
    });

    builder.addCase(saveOtherEarnings.rejected, (state, action) => {
      state.isUpdating = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(saveOtherEarnings.pending, state => {
      state.isUpdating = true;
      state.errors = [];
    });

    builder.addCase(savePaystubNotes.fulfilled, (state, action) => {
      const {
        id: paymentId,
        paystub_notes: paystubNotes,
        show_paystub_notes: showPaystubNotes,
      } = action.payload;

      state.isUpdating = false;
      state.errors = [];

      // Find the payment that was updated
      const payrollPaymentIndex = state.payrollPayments.findIndex(
        payrollPayment => payrollPayment.id === paymentId
      );
      if (payrollPaymentIndex === -1) {
        return;
      }

      const payrollPayment = state.payrollPayments[payrollPaymentIndex];
      payrollPayment.paystub_notes = paystubNotes;
      payrollPayment.show_paystub_notes = showPaystubNotes;

      state.payrollPayments[payrollPaymentIndex] = payrollPayment;
    });

    builder.addCase(savePaystubNotes.rejected, (state, action) => {
      state.isUpdating = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(savePaystubNotes.pending, state => {
      state.isUpdating = true;
      state.errors = [];
    });

    builder.addCase(saveViewSettings.fulfilled, state => {
      state.isUpdating = false;
      state.errors = [];
    });

    builder.addCase(saveViewSettings.rejected, (state, action) => {
      state.isUpdating = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(saveViewSettings.pending, state => {
      state.isUpdating = true;
      state.errors = [];
    });

    builder.addCase(updateEarning.rejected, (state, action) => {
      state.earning.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(updateEarning.pending, state => {
      state.earning.isFetching = true;
      state.errors = [];
    });

    builder.addCase(updateEarning.fulfilled, (state, action) => {
      const { isSingularEarning, wageIndex } = action.meta.arg;
      const {
        updated_earning: earning,
        salaried_earning: salariedEarning,
        tip_shortage_earning: tipShortageEarning,
        payment,
        totals,
        gross,
      } = action.payload;

      state.headerSubmissionDeadline =
        action.payload.header_submission_deadline;
      state.actualSubmissionDeadlineCountdown =
        action.payload.actual_submission_deadline_countdown;

      state.earning.isFetching = false;
      state.errors = [];
      state.totals.current = totals;

      // Find the payment that was requested to updated
      const payrollPaymentIndex = state.payrollPayments.findIndex(
        payrollPayment =>
          payrollPayment.id === payment.id &&
          payrollPayment.payment_type === payment.payment_type
      );
      if (payrollPaymentIndex === -1) {
        return;
      }

      const payrollPayment = state.payrollPayments[payrollPaymentIndex];
      payrollPayment.gross.current = gross;

      /* Singular earnings are cash tips, paycheck tips, bonus, severance, commission,
       * tip shortage, other_imputed, group_term_life, reimbursement and correction.
       * They are store in a collection at the Payment level.
       * We need to check whether earning is a singular earning
       * and find it in the singular_earnings collection
       */
      if (isSingularEarning) {
        payrollPayment.singular_earnings[earning.column] = earning;
      }

      if (tipShortageEarning) {
        payrollPayment.singular_earnings[tipShortageEarning.column] =
          tipShortageEarning;
      }

      /* If wageIndex is greater than zero it means that the earning is stored at the wage level.
       * Get the wage with the wageIndex and update earning
       */
      if (wageIndex >= 0) {
        const wage = payrollPayment.wages[wageIndex];
        wage.earnings[earning.column] = earning;

        /* If payment is salaried the response could include a salaried_earning object.
         * It's used to update the regular hours after adding PTO, Sick or Paid Holiday
         * We need to update that earning too
         */
        if (salariedEarning) {
          wage.earnings[salariedEarning.column] = salariedEarning;
        }
        payrollPayment.wages[wageIndex] = wage;
      }

      state.payrollPayments[payrollPaymentIndex] = payrollPayment;
    });

    builder.addCase(updatePaymentMethod.rejected, (state, action) => {
      state.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(updatePaymentMethod.pending, state => {
      state.isFetching = true;
      state.errors = [];
    });

    builder.addCase(updatePaymentMethod.fulfilled, (state, action) => {
      const { totals } = action.payload;
      const { paymentId, paymentType, value } = action.meta.arg;

      state.isFetching = false;
      state.errors = [];

      state.headerSubmissionDeadline =
        action.payload.header_submission_deadline;
      state.actualSubmissionDeadlineCountdown =
        action.payload.actual_submission_deadline_countdown;

      if (totals) {
        state.totals.current = totals;
      }

      // Find the payment that was requested to updated
      const payrollPaymentIndex = state.payrollPayments.findIndex(
        payrollPayment =>
          payrollPayment.id === paymentId &&
          payrollPayment.payment_type === paymentType
      );
      if (payrollPaymentIndex === -1) {
        return;
      }

      state.payrollPayments[payrollPaymentIndex].payment_method = value;
    });

    builder.addCase(previewPayroll.fulfilled, (state, action) => {
      state.isFetching = false;
      state.errors = [];
      state.netPayPayments = action.payload.net_pay_payments;
      state.netPayTotals = action.payload.net_pay_totals;
    });

    builder.addCase(previewPayroll.rejected, (state, action) => {
      state.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(previewPayroll.pending, state => {
      state.isFetching = true;
      state.errors = [];
    });

    builder.addCase(getFinalCosts.fulfilled, (state, action) => {
      state.isFetching = false;
      state.submitView.totalManualPayments =
        action.payload.total_manual_payments;
      state.submitView.totalDebitPayments = action.payload.total_debit_payments;
      state.submitView.hasManualPayments = action.payload.has_manual_payments;
      state.submitView.hasPaperCheckPayments =
        action.payload.has_paper_check_payments;
      state.submitView.nonSufficientFunds = action.payload.non_sufficient_funds;
      state.submitView.manualPaymentsDue = action.payload.manual_payments_due;
      state.submitView.paymentsAlreadyReceivedTotal =
        action.payload.payments_already_received_total;
      state.submitView.isContractorsOnlyCompany =
        action.payload.is_contractors_only_company;
      state.submitView.showAutoPayrollPromo =
        action.payload.show_auto_payroll_promo;
      state.errors = [];
    });

    builder.addCase(getFinalCosts.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(getFinalCosts.pending, state => {
      state.isFetching = true;
      state.errors = [];
    });

    builder.addCase(approvePayroll.fulfilled, state => {
      state.isFetching = false;
      state.openPayrollRunId = null;
      state.errors = [];
    });

    builder.addCase(approvePayroll.rejected, (state, action) => {
      state.isFetching = false;
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(approvePayroll.pending, state => {
      state.isFetching = true;
      state.errors = [];
    });

    builder.addCase(deletePayroll.pending, state => {
      state.isFetching = true;
    });

    builder.addCase(deletePayroll.fulfilled, state => {
      state.isFetching = false;
    });

    builder.addCase(deletePayroll.rejected, state => {
      state.isFetching = false;
    });

    builder.addCase(fetchTimecardsForRole.fulfilled, (state, action) => {
      state.timecards = action.payload.timecards;
      state.errors = [];
    });

    builder.addCase(fetchTimecardsForRole.rejected, (state, action) => {
      state.errors = action.payload.errors;
      state.errorType = action.payload.error_type;
    });

    builder.addCase(fetchTimecardsForRole.pending, state => {
      state.errors = [];
    });
  },
});

// selectors
export const getShowCoachmarks = state =>
  state.get('payroll').payrollRun.showCoachmarks;

export const getCanShowManualHoursModal = state =>
  state.get('payroll').payrollRun.canShowManualHoursModal;

export const getPayrollRunLocations = state =>
  state.get('payroll').payrollRun.locations;

export const getShowTipsCoachmarks = state =>
  state.get('payroll').payrollRun.showTipsCoachmarks;

export const getShowMissingStateTaxAgreement = state =>
  state.get('payroll').payrollRun.showMissingStateTaxAgreement;

export const getMissingStateTaxAgreementDeadline = state =>
  state.get('payroll').payrollRun.missingStateTaxAgreementDeadline;

export const getShowCharityNpoEarnings = state =>
  state.getIn(['payroll']).payrollRun.showCharityNpoEarnings;

export const getShowReligiousNpoEarnings = state =>
  state.getIn(['payroll']).payrollRun.showReligiousNpoEarnings;

export const getSubmitPayrollBlocked = state =>
  state.get('payroll').payrollRun.submitPayrollBlocked;

export const getErrorType = state => state.get('payroll').payrollRun.errorType;

export const getPayrollRunInputHours = state =>
  state.get('payroll').payrollRun.inputHours;

export const getAutoUpdatePayments = state =>
  state.get('payroll').payrollRun.autoUpdatePayments;

export const getIsContractorsOnlyCompany = state =>
  state.get('payroll').payrollRun.submitView.isContractorsOnlyCompany;

export const getShowAutoPayrollPromo = state =>
  state.get('payroll').payrollRun.submitView.showAutoPayrollPromo;

export const getViewSettings = state =>
  state.get('payroll').payrollRun.viewSettings;

export const getShowColumnSettings = state =>
  state.get('payroll').payrollRun.showColumnSettings;

export const getShowFilters = state =>
  state.get('payroll').payrollRun.showFilters;

export const payrollRunReducer = payrollRunSlice.reducer;
export const payrollRunActions = payrollRunSlice.actions;
