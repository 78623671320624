// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck TODO[redux-toolkit-upgrade]: Fix all type errors

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as routes from 'api';
import { postJSON } from 'api/fetch';
import { pollWorkerStatus } from 'api/workerStatus';
import { difference } from 'lodash';
import Shift from 'resources/Shift';

import { actionTypes as addTeamActions } from 'actions/addTeam';
import { actionTypes as employeeViewActionTypes } from 'actions/employeeView';
import { actionTypes as timeOffsActions } from 'actions/timeOff';

import { toI18n } from 'util/i18n';
import { getObjectFromLocalStorage } from 'util/localStorageWrapper';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import { SYNC_STATES } from '../types';

import CompanyLaborTotals from './resources/./CompanyLaborHours';
import RoleLaborTotals from './resources/RoleLaborTotals';
import Schedule from './resources/Schedule';
import {
  DRAWERS,
  EMPLOYEE_VIEW_FILTERS,
  FILTERS,
  SLICE_NAME,
  VIEW_BY,
  VIEW_BY_DAY,
} from './constants';
import { getInitialStateFromLS } from './helpers';
import {
  PublishScheduleProps,
  ShiftSyncStatusProps,
  SyncShiftProps,
} from './types';

/* eslint-disable @typescript-eslint/no-explicit-any */
const setDrawer = (state: any, drawer: any, uxContext = {}) => {
  state.activeDrawer = drawer;
  state.drawerUxContext = uxContext;
};

const checkForJobUpdate = (state: any, { payload }: { payload: any }) => {
  const { id, type } = payload.data.relationships.owner.data;
  if (type === 'job') {
    state.pendingJobLaborTotalsIds.push(id);
  }
};

const slice = createSlice({
  name: SLICE_NAME,

  initialState: {
    currentDateRange: null,
    conflictsEnabled: false,
    viewType: 'employee',
    rangeType: 'week',
    searchTerm: '',
    hasPendingData: false,
    isJumpstartClosed: false,
    pendingJobLaborTotalsIds: [] as number[],
    companyLaborTotals: [],
    activeDrawer: null,
    employeeViewFilter: EMPLOYEE_VIEW_FILTERS.ALL,
    departmentId: null,
    notificationCenterMessage: null,
    notificationCenterType: null,
    customJobIds: [],
    isAvailabilitiesVisible: getInitialStateFromLS(
      FILTERS.IS_AVAILABILITIES_VISIBLE
    ),
    isTimeOffsVisible: getInitialStateFromLS(FILTERS.IS_TIME_OFFS_VISIBLE),
    isShiftsAtThisLocationOnly:
      getObjectFromLocalStorage(FILTERS.IS_SHIFTS_AT_THIS_LOCATION_ONLY) ||
      false,
    copyWeekModalIsOpen: false,
    sortByDay: getObjectFromLocalStorage(VIEW_BY_DAY) || VIEW_BY.firstName,
    copyPasteShift: null,
    syncErrors: [],
    syncShiftStatus: [] as ShiftSyncStatusProps[],
    partnerSyncState: undefined as SYNC_STATES,
  },

  reducers: {
    toggleConflicts: state => {
      state.conflictsEnabled = !state.conflictsEnabled;
    },
    closeJumpstartDrawer: state => {
      state.isJumpstartClosed = true;
      setDrawer(state, null);
    },
    showJumpstartDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.jumpstart_drawer, uxContext);
    },

    resetJumpstartDrawer: state => {
      state.isJumpstartClosed = false;
    },

    showPublishDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.publish_drawer, uxContext);
    },

    showPrintScheduleDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.print_schedule_drawer, uxContext);
    },

    showTemplatesDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.templates_drawer, uxContext);
    },

    showSendToPartnerDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.send_to_partner_drawer, uxContext);
    },

    hideDrawer: state => {
      if (state.activeDrawer === DRAWERS.publish_drawer)
        state.departmentId = null;

      setDrawer(state, null);
      state.partnerSyncState = undefined;
    },

    openCopyWeekModal: state => {
      state.copyWeekModalIsOpen = true;
    },

    closeCopyWeekModal: state => {
      state.copyWeekModalIsOpen = false;
    },

    showNotification: (state, { payload: { message, type } }) => {
      state.notificationCenterMessage = message;
      state.notificationCenterType = type;
    },

    hideNotification: state => {
      state.notificationCenterMessage = null;
      state.notificationCenterType = null;
    },

    setCurrentDateRange: (state, { payload: currentDateRange }) => {
      state.currentDateRange = currentDateRange;
    },

    setViewType: (state, { payload: viewType }) => {
      state.viewType = viewType;
    },

    setRangeType: (state, { payload: rangeType }) => {
      state.rangeType = rangeType;
    },

    setEmployeeViewFilter: (state, { payload: employeeViewFilter }) => {
      state.employeeViewFilter = employeeViewFilter;
      state.conflictsEnabled = false;
    },

    setSearchTerm: (state, { payload: searchTerm }) => {
      state.searchTerm = searchTerm;
    },

    loadJobTotals: (state, { payload: pendingJobLaborTotalsIds }) => {
      state.pendingJobLaborTotalsIds = pendingJobLaborTotalsIds;
    },

    setCustomJobIds: (state, { payload: customJobIds }) => {
      state.customJobIds = customJobIds;
    },

    setSortByDay: (state, { payload: sortByDay }) => {
      state.sortByDay = sortByDay;
    },

    setDepartmentId: (state, { payload: { departmentId } }) => {
      state.departmentId = departmentId;
    },

    startCopyPaste: (state, { payload: shiftId }) => {
      state.copyPasteShift = shiftId;
    },

    stopCopyPaste: state => {
      state.copyPasteShift = null;
    },

    updatePendingJobLaborTotalsIds: (state, { payload: id }) => {
      if (state.pendingJobLaborTotalsIds.includes(id) === false) {
        state.pendingJobLaborTotalsIds.push(id);
      }
    },

    updateShowHideAvailabilities: (state, { payload: isChecked }) => {
      state.isAvailabilitiesVisible = isChecked;
    },

    updateShowHideTimeOffs: (state, { payload: isChecked }) => {
      state.isTimeOffsVisible = isChecked;
    },

    updateShowHideShiftsLocationsOnly: (state, { payload: isChecked }) => {
      state.isShiftsAtThisLocationOnly = isChecked;
    },

    setSyncErrors: (state, { payload: syncErrors }) => {
      state.syncErrors = syncErrors;
    },
    setPartnerSyncState: (
      state,
      { payload: syncState }: { payload: SYNC_STATES }
    ) => {
      state.partnerSyncState = syncState;
    },
    setSyncShiftStatus: (state, { payload: syncShiftStatus }) => {
      state.syncShiftStatus = syncShiftStatus;
    },

    toggleSyncingToPartner: (state, { payload: status }) => {
      state.syncingToPartner = status;
    },
  },

  extraReducers: builder => {
    builder.addCase(Schedule.resourceActionEvent('fetch'), state => {
      state.hasPendingData = false;
    });

    builder.addCase(Shift.resourceActionEvent('create'), checkForJobUpdate);
    builder.addCase(Shift.resourceActionEvent('update'), checkForJobUpdate);

    builder.addCase(addTeamActions.UPDATE_EMPLOYEES_SUCCESS, state => {
      state.hasPendingData = true;
    });

    builder.addCase(timeOffsActions.ADD_TIME_OFF_SUCCESS, state => {
      state.hasPendingData = true;
    });

    builder.addCase(addTeamActions.CREATE_EMPLOYEE_SUCCESS, (state, action) => {
      const jobId = action.payload.jobs[0].id;
      // add ID of a new employee to pending job labor array
      // to fetch labor totals for this new Job
      state.pendingJobLaborTotalsIds.push(jobId);
      // Set pending data flag to true to refetch the whole schedule because of a new employee
      state.hasPendingData = true;
    });

    builder.addCase(
      RoleLaborTotals.resourceActionEvent('post'),
      (state, action) => {
        state.pendingJobLaborTotalsIds = difference(
          state.pendingJobLaborTotalsIds,
          action.payload.meta.jobIds
        );
      }
    );

    builder.addCase(
      CompanyLaborTotals.resourceActionEvent('fetch'),
      (state, action) => {
        state.companyLaborTotals = action.payload.data;
      }
    );

    builder.addCase(employeeViewActionTypes.TERMINATION_FORM_SUCCESS, state => {
      state.hasPendingData = true;
    });
  },
});

const handleErrors = (
  errors: string[],
  { payload }: SyncShiftProps,
  dispatch: any
) => {
  if (errors.length > 0) {
    if (errors.length === payload.syncedShifts) {
      dispatch(slice.actions.setPartnerSyncState('NONE_SENT'));
    } else {
      dispatch(slice.actions.setPartnerSyncState('PARTIALLY_SENT'));
    }
    dispatch(slice.actions.setSyncErrors(errors));
  } else {
    dispatch(slice.actions.setPartnerSyncState('ERROR'));
  }
};

const handleSuccess = (dispatch: any) => {
  dispatch(slice.actions.setPartnerSyncState('FULLY_SENT'));
};

export const syncShifts = createAsyncThunk(
  'scheduleBuilder/syncShifts',
  async ({ payload }: SyncShiftProps, { dispatch }: { dispatch: any }) => {
    /* reset error state */
    dispatch(slice.actions.setSyncErrors([]));

    try {
      const response = await postJSON(routes.syncShiftsRoute(), {
        ...payload,
      });
      const jobId = response?.job_id;
      if (jobId) {
        pollWorkerStatus(jobId)
          .then((resp: { errors: string }) => {
            const errors = JSON.parse(resp.errors);

            /* on polling finish, handle job errors */
            if (errors.length > 0) {
              handleErrors(errors, { payload }, dispatch);
            } else {
              /* on polling finish, handle job success */
              handleSuccess(dispatch);
            }
          })
          .finally(async () => {
            /* refresh shift data after polling */
            const shiftSyncData = await postJSON(
              routes.syncShiftStatusRoute(),
              {
                start_date: payload.start_date,
                end_date: payload.end_date,
              }
            );
            dispatch(slice.actions.setSyncShiftStatus(shiftSyncData));
          })
          .catch(() => {
            /* on polling failure, display generic error */
            handleErrors([], { payload }, dispatch);
          });
      }
    } catch (error: unknown) {
      /* full query failure, display error message */
      handleErrors([], { payload }, dispatch);
    }
  }
);

export const publishSchedule = createAsyncThunk(
  'scheduleBuilder/publishScheduleAction',
  async ({ payload }: PublishScheduleProps, { dispatch }) => {
    try {
      await postJSON(routes.publishScheduleAPIRoute(), {
        ...payload,
      });
      const shiftSyncData = await postJSON(routes.syncShiftStatusRoute(), {
        start_date: payload.start_date,
        end_date: payload.end_date,
      });
      /* FE-CORE is necessary here to refetch the schedule */
      dispatch(
        Schedule.fetch({
          payload: {
            start_date: payload.start_date,
            end_date: payload.end_date,
          },
          id: '',
          beforeDigestCallback: () => {},
        })
      );
      dispatch(slice.actions.setSyncShiftStatus(shiftSyncData));
    } catch (error) {
      dispatch(
        slice.actions.showNotification({
          message: toI18n('errors.generic'),
          type: 'error',
        })
      );
    }

    trackUxEvent({
      productArea: PRODUCT_AREAS.SCHEDULE_BUILDER,
      eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER_PUBLISH_DRAWER,
      eventAction: EVENT_ACTIONS.SCHEDULE_PUBLISHED,
    });
  }
);

export const {
  showSendToPartnerDrawer,
  setDepartmentId,
  hideDrawer,
  setPartnerSyncState,
  showNotification,
} = slice.actions;

export const { actions, reducer } = slice;
