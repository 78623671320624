import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';
import { camelCase } from 'lodash';

import {
  createBusiness,
  createEmployeeBusiness,
  employeeCheck,
  employeeCreate,
  preSignupEnrollInExperiment,
} from './actions';
import { SIGNUP_STEPS, SLICE_NAME, USER_MOTIVATION_KEYS } from './constants';

const {
  OWNER_INFO: { name: OWNER_INFO },
  OWNER_MOTIVATION: { name: OWNER_MOTIVATION },
  BUSINESS_INFO: { name: BUSINESS_INFO },
  ACCOUNT_DETAILS: { name: ACCOUNT_DETAILS },
} = SIGNUP_STEPS;

const { SCHEDULING, TIME_TRACKING, PAYROLL } = USER_MOTIVATION_KEYS;

// Form Steps (completedStep)
//
// 0 - Haven't started
// 1 - Completed OwnerInfo
// 2 - Completed OwnerMotivation
// 3 - Completed BusinessInfo
// 4 - Completed AccountDetails

export const initialState = fromJS({
  [OWNER_INFO]: {
    fullName: '',
    womAnswer: '',
    homebaseAdvocateInfoPhone: '',
    homebaseAdvocateInfoEmail: '',
  },
  [OWNER_MOTIVATION]: {
    [SCHEDULING]: false,
    [TIME_TRACKING]: false,
    [PAYROLL]: false,
    businessAge: '',
    payrollHowYouPayToday: '',
    expectedLaunchDate: undefined,
  },
  [BUSINESS_INFO]: {
    businessName: '',
    businessZip: '',
    businessDescription: '',
    businessSize: '',
    businessExtendedSize: '',
    multiLocationDetailed: '',
    geo: {},
  },
  [ACCOUNT_DETAILS]: {
    email: '',
    phone: '',
    password: '',
    textLink: true,
  },
  completedStep: 0,
  submitPending: false,
  serverErrors: {},
  locationNameCityState: null,
  loadingExperimentValue: true,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    setLocationNameCityState: (state, action) =>
      state.set('locationNameCityState', action.payload),

    setInitialState: () => initialState,

    setOwnerInfo: (state, { payload: ownerInfo }) => {
      const completedStep = state.getIn([SLICE_NAME, 'completedStep']);
      return state
        .set(OWNER_INFO, fromJS(ownerInfo))
        .set('completedStep', completedStep < 1 ? 1 : completedStep);
    },

    setOwnerMotivation: (state, { payload: ownerMotivation }) => {
      const completedStep = state.getIn([SLICE_NAME, 'completedStep']);

      return state
        .set(OWNER_MOTIVATION, fromJS(ownerMotivation))
        .set('completedStep', completedStep < 2 ? 2 : completedStep);
    },

    setBusinessInfo: (state, { payload: businessInfo }) => {
      const completedStep = state.getIn([SLICE_NAME, 'completedStep']);

      return state
        .set(BUSINESS_INFO, fromJS(businessInfo))
        .set('completedStep', completedStep < 3 ? 3 : completedStep);
    },

    setAccountDetails: (state, { payload: accountDetails }) => {
      const completedStep = state.getIn([SLICE_NAME, 'completedStep']);

      return state
        .set(ACCOUNT_DETAILS, fromJS(accountDetails))
        .set('completedStep', completedStep < 4 ? 4 : completedStep);
    },
  },

  extraReducers: builder => {
    builder.addCase(createBusiness.pending, state =>
      state.set('submitPending', true).set('serverErrors', fromJS({}))
    );

    builder.addCase(createBusiness.fulfilled, state =>
      state.set('completedStep', 4)
    );

    builder.addCase(createBusiness.rejected, (state, { payload }) =>
      state.set('submitPending', false).set('serverErrors', fromJS(payload))
    );

    builder.addCase(employeeCheck.pending, state =>
      state.merge({
        serverErrors: null,
        submitPending: true,
      })
    );

    builder.addCase(employeeCheck.fulfilled, (state, { meta: { arg } }) =>
      state.merge({
        validatedUser: arg,
        submitPending: false,
      })
    );

    builder.addCase(employeeCheck.rejected, (state, { payload }) =>
      state.merge({
        submitPending: false,
        serverErrors: payload,
      })
    );

    builder.addCase(employeeCreate.pending, state =>
      state.merge({
        serverErrors: null,
        submitPending: true,
      })
    );

    builder.addCase(employeeCreate.fulfilled, (state, { meta: { arg } }) =>
      state.merge({
        validatedUser: arg,
        submitPending: false,
      })
    );

    builder.addCase(employeeCreate.rejected, (state, { payload }) =>
      state.merge({
        submitPending: false,
        serverErrors: payload,
      })
    );

    builder.addCase(createEmployeeBusiness.pending, state =>
      state.merge({
        serverErrors: null,
        submitPending: true,
      })
    );

    builder.addCase(createEmployeeBusiness.fulfilled, state =>
      state.merge({
        submitPending: false,
        serverErrors: null,
      })
    );

    builder.addCase(createEmployeeBusiness.rejected, (state, { payload }) =>
      state.merge({
        submitPending: false,
        serverErrors: payload,
      })
    );

    builder.addCase(preSignupEnrollInExperiment.pending, state =>
      state.merge({
        loadingExperimentValue: true,
      })
    );

    builder.addCase(
      preSignupEnrollInExperiment.fulfilled,
      (state, { payload: { name, value } }) =>
        state.merge({
          loadingExperimentValue: false,
          [camelCase(name)]: value,
        })
    );

    builder.addCase(preSignupEnrollInExperiment.rejected, state =>
      state.merge({
        loadingExperimentValue: false,
      })
    );
  },
});

export const { actions, reducer } = slice;
