import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastProps } from 'fe-design-base/molecules/Toast/Toast';

import { SLICE_NAME } from './constants';

export interface GlobalToastState {
  isToastOpen: boolean;
  content: string | undefined;
  initiatedFrom?: string;
  // open & children cannot be passed in the reducer so we exclude it
  toastProps?: Omit<ToastProps, 'open' | 'children'> | Record<string, never>;
}

const initialState: GlobalToastState = {
  isToastOpen: false,
  content: undefined,
  initiatedFrom: undefined,
  toastProps: {},
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    openGlobalToast: (state, action: PayloadAction<GlobalToastState>) => {
      state.isToastOpen = true;
      state.content = action.payload.content;
      state.initiatedFrom = action.payload.initiatedFrom;
      state.toastProps = action.payload.toastProps;
    },

    closeGlobalToast: state => {
      state.isToastOpen = false;
      state.content = undefined;
      state.initiatedFrom = undefined;
      // we do not reset toastProps to prevent UI from flashing the default state
    },
  },
});

export const { actions, reducer } = slice;
