import './SwitchToPayrollBanner.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';
import Pill from 'fe-design-base/molecules/Pill';
import { useIsMediumLargeScreen } from 'fe-design-base/utils/useMediaQuery';
import { useCountDownTimer } from 'hooks/useCountDownTimer';

import {
  getCurrentLocationId,
  getCurrentUserId,
  getProductGrowthSiteWideBannerRolloutEnabled,
  getSiteWideBannerExperimentValue,
} from 'selectors/session';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { PRODUCT_AREAS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useExtendUx, useTrackUxHandler, withUxRoot } from 'util/uxEvents';

const { cx, cxEl } = cxHelpers('SwitchToPayrollBanner');

export const SwitchToPayrollBanner = ({
  currentUserId,
  currentLocationId,
  siteWideBannerRolloutEnabled,
  siteWideBannerExperimentValue,
}: {
  currentUserId: number;
  currentLocationId: number;
  siteWideBannerRolloutEnabled: boolean;
  siteWideBannerExperimentValue: number;
}) => {
  const browserLocation = useLocation();
  const pageIsPayrollPath = browserLocation.pathname.includes('payroll');
  const isLargeScreen = useIsMediumLargeScreen();
  const extendUxEvent = useExtendUx();

  const [showBanner, setShowBanner] = useState(!pageIsPayrollPath);

  extendUxEvent({
    ...(siteWideBannerRolloutEnabled && {
      productgrowth_site_wide_banner: siteWideBannerExperimentValue,
    }),
  });

  const trackViewEvent = useTrackUxHandler(
    'EOY Banner Viewed',
    TRACK_ACTION_TYPES.VIEW
  );

  const trackCloseEvent = useTrackUxHandler(
    'EOY Banner Dismissed',
    TRACK_ACTION_TYPES.CLICK
  );

  const trackLinkClickEvent = useTrackUxHandler(
    'EOY Banner Clicked',
    TRACK_ACTION_TYPES.CLICK
  );

  useEffect(() => {
    setShowBanner(!pageIsPayrollPath);
  }, [pageIsPayrollPath]);

  useEffect(() => {
    if (showBanner) trackViewEvent();
  }, [showBanner, trackViewEvent]);

  const handleClose = useCallback(() => {
    trackCloseEvent();
    setShowBanner(false);
    return postJSON('/monetization/dismiss_eoy_banner', {
      location_id: currentLocationId,
      user_id: currentUserId,
    });
  }, [currentLocationId, currentUserId, trackCloseEvent]);

  const onHideTimer = useCallback(() => setShowBanner(false), []);

  const timeLeft = useCountDownTimer({
    onHideTimer,
    hideTimer: !showBanner,
  });

  const bannerCopyVariant = useMemo(() => {
    if (!siteWideBannerRolloutEnabled) {
      return 0;
    }

    return siteWideBannerExperimentValue;
  }, [siteWideBannerExperimentValue, siteWideBannerRolloutEnabled]);

  if (!showBanner || !timeLeft) {
    return null;
  }

  return (
    <Box
      row
      alignItemsCenter
      ph={16}
      pv={25}
      className={cx()}
      bgcolor="primary100"
    >
      <Box
        wrap
        gap={8}
        ph={16}
        grow={1}
        color="primary900"
        column={!isLargeScreen}
        justifyContentCenter={isLargeScreen}
      >
        <Box className={cxEl('pill')}>
          <Pill variant="highlight">
            {toI18n('switch_to_payroll_banner.pill')}
          </Pill>
        </Box>

        <Box row wrap gap={3}>
          <Text
            variant="bodyBold"
            i18n={`switch_to_payroll_banner.${bannerCopyVariant}.description_1`}
          />

          <Text
            i18n={`switch_to_payroll_banner.${bannerCopyVariant}.description_2`}
          />
        </Box>

        <Link
          onClick={trackLinkClickEvent}
          // eslint-disable-next-line max-len
          href={`/payroll?utm_source=${window.Homebase.env}&utm_medium=in_app_banner&utm_campaign=pr24&utm_content=primary_banner&utm_term=1&from_eoy_payroll_banner=true`}
        >
          {toI18n('switch_to_payroll_banner.switch_to_payroll')}
        </Link>
      </Box>

      <Box>
        <Icon
          onClick={handleClose}
          size="small"
          iconName="Close"
          color="primary500"
          dataTestId="dismiss-icon"
        />
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    currentUserId: getCurrentUserId(state),
    currentLocationId: getCurrentLocationId(state),
    siteWideBannerRolloutEnabled:
      getProductGrowthSiteWideBannerRolloutEnabled(state),
    siteWideBannerExperimentValue: getSiteWideBannerExperimentValue(state),
  }),
  {}
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.GLOBAL,
    eventCategory: 'payroll_eoy_banner',
  })(SwitchToPayrollBanner)
);
