import { List } from 'immutable';
import { createSelector } from 'reselect';

import { getIsFetching } from 'selectors/fetching';

import { toEntityId } from 'util/entities';

export const getJobs = state => state.getIn(['entities', 'jobs']);
export const getJobRequests = state => state.getIn(['entities', 'jobRequests']);
export const getJobRequestBoosts = state =>
  state.getIn(['entities', 'jobRequestBoosts']);
export const getCompanyProfiles = state =>
  state.getIn(['entities', 'companyProfiles']);
export const getLocationProfiles = state =>
  state.getIn(['entities', 'locationProfiles']);
export const getApplicants = state => state.getIn(['entities', 'applicants']);
export const getCompanyUsers = state => state.getIn(['entities', 'users']);
export const getGmUserNames = state => state.getIn(['entities', 'gmUserNames']);
export const getManagerialUserNames = state =>
  state.getIn(['entities', 'managerialUserNames']);
export const getApplications = state =>
  state.getIn(['entities', 'applications']);
export const getApplicationConversations = state =>
  state.getIn(['entities', 'applicationConversations']);
export const getApplicationMessages = state =>
  state.getIn(['entities', 'applicationMessages']);
export const getLocationsIndex = state =>
  state.getIn(['entities', 'locations']);
export const getStandardizedRoles = state =>
  state.getIn(['entities', 'standardizedRoles']);
export const getStandardizedRolesLoaded = state =>
  state.getIn(['entities', 'loaded', 'standardizedRoles']);

export const getAllJobRequestBoosts = createSelector(
  getJobRequestBoosts,
  boosts => boosts.valueSeq()
);

export const getSortedStandardizedRoles = createSelector(
  getStandardizedRoles,
  roles =>
    roles
      .toSeq()
      .sortBy(r => r.get('name'))
      .toList()
);

export const createLoadedSelector = (entity, scope) => state =>
  state.getIn(['entities', 'loaded', entity, scope]);

export const getJobIdFromProps = (_, props) =>
  props.jobId || props.match.params.jobId;
export const getUserIdFromProps = (_, props) =>
  props.userId || props.match.params.userId;
export const getLocationIdFromProps = (_, props) =>
  props.locationId || props.match.params.locationId;
export const getIdFromProps = (_, props) => props.id;
export const getJobRequestIdFromProps = (_, props) => props.jobRequestId;

const findEntity = (entities, id) => entities.get(toEntityId(id));

export const getJobFromProps = createSelector(
  getJobs,
  getJobIdFromProps,
  findEntity
);
export const getJobRequestFromProps = createSelector(
  getJobRequests,
  getIdFromProps,
  getJobRequestIdFromProps,
  (jobRequests, idFromProps, jobRequestIdFromProps) =>
    findEntity(jobRequests, idFromProps || jobRequestIdFromProps)
);

export const getEligiblePartners = createSelector(
  getJobRequests,
  getIdFromProps,
  getJobRequestIdFromProps,
  (jobRequests, idFromProps, jobRequestIdFromProps) =>
    findEntity(jobRequests, idFromProps || jobRequestIdFromProps)
      .get('eligible_boosts')
      .toJSON()
      .map(eligiblePartner => eligiblePartner.name)
);

export const getNumLocations = createSelector(
  getLocationsIndex,
  locations => locations.size
);

export const getLocations = createSelector(getLocationsIndex, locations =>
  locations.sortBy(location => location.get('name'))
);

export const getSortedLocations = createSelector(getLocationsIndex, locations =>
  locations
    .sortBy(location => location.get('id'))
    .map(location => ({
      id: location.get('id'),
      name: location.get('name'),
    }))
    .toArray()
);

export const getLocationFromProps = createSelector(
  getLocationsIndex,
  getLocationIdFromProps,
  findEntity
);

export const getIsFetchingApplicationConversations = state =>
  getIsFetching(state, {
    requestId: 'FETCH_APPLICATION_CONVERSATIONS',
  });

export const getLocationNameFromJobIdInProps = createSelector(
  getLocationsIndex,
  getJobFromProps,
  (locations, job) => {
    if (!job) {
      return false;
    }
    const location = findEntity(locations, job.get('location_id'));
    return location && location.get('name');
  }
);

export const getLocationNameFromLocationIdInProps = createSelector(
  getLocationsIndex,
  getLocationIdFromProps,
  (locations, id) => {
    const location = findEntity(locations, id);
    return location && location.get('name');
  }
);

export const createEntitiesForViewSelector = (entityType, view) =>
  createSelector(
    state => state.getIn(['entities', entityType]),
    entities => entities.filter(entity => entity.getIn(['forViews', view]))
  );

const getDrawerApplicationConversationsRaw = createEntitiesForViewSelector(
  'applicationConversations',
  'drawerApplicationConversations'
);

export const getDrawerApplicationConversations = createSelector(
  getDrawerApplicationConversationsRaw,
  conversations =>
    conversations
      .sortBy(c => c.getIn(['messages']).first().get('id'))
      .reverse()
      .toList()
);

export const createLocationsForViewSelector = view =>
  createSelector(createEntitiesForViewSelector('locations', view), locations =>
    locations.sortBy(location => location.get('name'))
  );

export const createLocationRolesSelector = locationsSelector =>
  createSelector(locationsSelector, locations => {
    if (!locations || locations.size === 0) {
      return List();
    }

    return locations
      .first()
      .get('roles')
      .toSeq()
      .sortBy(r => r.get('name'))
      .toList();
  });

const getUpdateLocation = state =>
  state.getIn(['_request', 'locations', 'updateLocation']);

export const getUpdateLocationPending = createSelector(
  getUpdateLocation,
  updateLocation => (updateLocation ? updateLocation.get('pending') : false)
);

export const getUpdateLocationFailed = createSelector(
  getUpdateLocation,
  updateLocation => (updateLocation ? updateLocation.get('failed') : false)
);

export const getUpdateLocationError = createSelector(
  getUpdateLocation,
  updateLocation => (updateLocation ? updateLocation.get('error') : {})
);
